import { StaticPageConfig } from "@7egend/dxp.core/lib/modules/cms/StaticPages/types";

export const CLUBS_PAGE: StaticPageConfig = {
    sections: {
        "2": {
            image: "../assets/pages/clubs.svg",
            containers: {
                "clubs/banner_1": {
                    blocks: [
                        {
                            types: ["3_2"],
                        },
                        {
                            types: ["3_3"],
                        },
                        {
                            types: ["3_1"],
                        },
                        {
                            types: ["201_1"],
                        },
                    ]
                },
                "clubs/banner_2": {
                    blocks: [
                        {
                            types: ["3_3"],
                        },
                        {
                            types: ["3_1"],
                        },
                        {
                            types: ["201_1"],
                        },
                    ]
                },
                "clubs/video": {
                    blocks: [
                        {
                            types: ["201_2"],
                        },
                    ]
                },
                "clubs/advantages": {
                    blocks: [
                        {
                            types: ["5"],
                            container: {
                                blocks: [
                                    {
                                        types: ["201_1"],
                                    },
                                    {
                                        types: ["3_3"],
                                    },
                                    {
                                        types: ["3_1"],
                                    },
                                ],
                            },
                        },
                        {
                            types: ["5"],
                            container: {
                                blocks: [
                                    {
                                        types: ["201_1"],
                                    },
                                    {
                                        types: ["3_3"],
                                    },
                                    {
                                        types: ["3_1"],
                                    },
                                ],
                            },
                        },
                        {
                            types: ["5"],
                            container: {
                                blocks: [
                                    {
                                        types: ["201_1"],
                                    },
                                    {
                                        types: ["3_3"],
                                    },
                                    {
                                        types: ["3_1"],
                                    },
                                ],
                            },
                        },
                        {
                            types: ["5"],
                            container: {
                                blocks: [
                                    {
                                        types: ["201_1"],
                                    },
                                    {
                                        types: ["3_3"],
                                    },
                                    {
                                        types: ["3_1"],
                                    },
                                ],
                            },
                        },
                    ]
                },
            }
        }
    }
};