import { StaticPageConfig } from "@7egend/dxp.core/lib/modules/cms/StaticPages/types";

export const PERSONAL_TRAINING_PAGE: StaticPageConfig = {
    sections: {
        "3": {
            image: "../assets/pages/personal-training.svg",
            containers: {
                "personal-training/overview": {
                    blocks: [
                        {
                            types: ["3_3"],
                        },
                        {
                            types: ["3_1"],
                        },
                        {
                            types: ["201_1"],
                        },
                    ]
                },
                "personal-training/training": {
                    blocks: [
                        {
                            types: ["201_1"],
                        },
                        {
                            types: ["3_3"],
                        },
                        {
                            types: ["3_1"],
                        },
                        {
                            types: ["201_1"],
                        },
                    ]
                },
                "personal-training/small_group": {
                    blocks: [
                        {
                            types: ["201_1"],
                        },
                        {
                            types: ["3_3"],
                        },
                        {
                            types: ["3_1"],
                        },
                        {
                            types: ["201_1"],
                        },
                    ]
                },
                "personal-training/tips": {
                    blocks: [
                        {
                            types: ["3_2"],
                        },
                        {
                            types: ["3_1"],
                        },
                    ]
                },
                "personal-training/video": {
                    blocks: [
                        {
                            types: ["201_2"],
                        },
                    ]
                },
                "personal-training/booster": {
                    blocks: [
                        {
                            types: ["3_1"],
                        },
                    ]
                },
                "personal-training/book_personal_trainer": {
                    blocks: [
                        {
                            types: ["12"],
                        },
                    ]
                },
            }
        }
    }
};