import { StaticPageConfig } from "@7egend/dxp.core/lib/modules/cms/StaticPages/types";

export const HOME_PAGE: StaticPageConfig = {
    sections: {
        "1": {
            image: "../assets/pages/home.svg",
            containers: {
                "homepage/banner": {
                    blocks: [
                        {
                            types: ["201_1"],
                            label: "Desktop",
                        },
                        {
                            types: ["201_1"],
                            label: "Mobile",
                        },
                        {
                            types: ["3_1"],
                        },
                        {
                            types: ["8_1"],
                        },
                    ]
                },
                "homepage/philosophy": {
                    blocks: [
                        {
                            types: ["3_2"],
                        },
                        {
                            types: ["3_3"],
                        },
                        {
                            types: ["3_1"],
                        },
                        {
                            types: ["201_1"],
                        },
                    ]
                },
                "homepage/video": {
                    blocks: [
                        {
                            types: ["201_2"],
                        },
                    ]
                },
                "homepage/why_choose": {
                    blocks: [
                        {
                            types: ["3_2"],
                        },
                        {
                            types: ["5"],
                            container: {
                                blocks: [
                                    {
                                        types: ["201_1",],
                                    },
                                    {
                                        types: ["3_3"],
                                    },
                                    {
                                        types: ["3_1"],
                                    },
                                ],
                            },
                        },
                        {
                            types: ["5"],
                            container: {
                                blocks: [
                                    {
                                        types: ["201_1",],
                                    },
                                    {
                                        types: ["3_3"],
                                    },
                                    {
                                        types: ["3_1"],
                                    },
                                ],
                            },
                        },
                        {
                            types: ["5"],
                            container: {
                                blocks: [
                                    {
                                        types: ["201_1",],
                                    },
                                    {
                                        types: ["3_3"],
                                    },
                                    {
                                        types: ["3_1"],
                                    },
                                ],
                            },
                        },
                        {
                            types: ["5"],
                            container: {
                                blocks: [
                                    {
                                        types: ["201_1",],
                                    },
                                    {
                                        types: ["3_3"],
                                    },
                                    {
                                        types: ["3_1"],
                                    },
                                ],
                            },
                        },
                    ]
                },
                "homepage/lets_train": {
                    blocks: [
                        {
                            types: ["3_1"],
                        },
                        {
                            types: ["8_1"],
                        },
                        {
                            types: ["201_1"],
                            label: "Desktop",
                        },
                        {
                            types: ["201_1"],
                            label: "Mobile",
                        },
                    ]
                },
                "homepage/find_us": {
                    blocks: [
                        {
                            types: ["3_3"],
                        },
                        {
                            types: ["3_1"],
                        },
                    ]
                },
                "homepage/latest_news": {
                    blocks: [
                        {
                            types: ["14_1"],
                        }
                    ]
                },
                "homepage/free_pass": {
                    blocks: [
                        {
                            types: ["3_1"],
                        },
                        {
                            types: ["8_1"],
                        },
                        {
                            types: ["201_1"],
                            label: "Desktop",
                        },
                        {
                            types: ["201_1"],
                            label: "Mobile",
                        },
                    ]
                },
            }
        }
    }
}