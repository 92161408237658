import { bootstrap, BlockType } from "@7egend/dxp.core";
import { Config } from "@7egend/dxp.core/lib/base/config";
import {
    DloExtension,
    DloInput,
    mergeQLQueries,
} from "@7egend/web.core/lib/extensions/dlo";

// Import Configs
import { STATIC_PAGES_CONFIG } from "./configs/staticPages";
import { ConfigFlags } from "@7egend/dxp.core/lib/base/flags";
import { ChannelsConfig } from "@7egend/dxp.core/lib/modules/cms/base/config/channels";
import { NewsEditorOverride } from "./screens/NewsEditor";
import {
    NewsGetByIdInput,
    NewsCreateInput,
    NewsUpdateInput,
} from "@7egend/dxp.core/lib/dlos/cms/news";

// Import Blocks
import { NewsWidget } from "./blocks/NewsWidget";

class DloExtensionOverride extends DloExtension {
    public call(input: DloInput, options?: any) {
        if (input.constructor === NewsGetByIdInput) {
            (input as NewsGetByIdInput).fields = mergeQLQueries(
                (input as NewsGetByIdInput).fields,
                ["localization"]
            );
        }

        if (input.constructor === NewsUpdateInput) {
            (input as NewsGetByIdInput).fields = mergeQLQueries(
                (input as NewsGetByIdInput).fields,
                ["localization"]
            );
        }

        if (input.constructor === NewsCreateInput) {
            (input as NewsGetByIdInput).fields = mergeQLQueries(
                (input as NewsGetByIdInput).fields,
                ["localization"]
            );
        }

        return super.call(input, options);
    }
}

bootstrap({
    modules: [
        () => {
            return {
                key: "cr7-fitness",
                routes: [
                    {
                        path: "cms/news/edit/:id",
                        component: NewsEditorOverride,
                    },
                ],
                menus: [],
                blocks: {
                    "cms.pages": [
                        // NewsWidget,
                        BlockType.Title,
                        BlockType.Text,
                        BlockType.Separator,
                    ],
                    "cms.news": [
                        BlockType.Title,
                        BlockType.Text,
                        BlockType.Highlight,
                        BlockType.Image,
                        BlockType.Album,
                        BlockType.Video,
                    ],
                    "cms.static-pages": [NewsWidget],
                },
            };
        },
    ],
    config: {
        staticPages: {
            ...STATIC_PAGES_CONFIG,
        },
        channels: [
            {
                key: "website",
                name: {
                    key: "news.editor.showOnFeedWeb",
                    en: "Show on feed (Website)",
                    es: "Mostrar en el feed (web)",
                    pt: "Mostrar no feed (site)",
                },
            },
        ] as ChannelsConfig,
        flags: {
            cms_polls: false,
            cms_boosters: false,
            cms_boosters_channels: false,
            cms_news_channels: true,
            cms_albums_channels: true,
            core_menu_featured: false,
            core_system: false,
        } as ConfigFlags & { [key: string]: string | boolean; },
    } as Partial<Config> | undefined,
    extensions: {
        dlo: DloExtensionOverride,
    },
    configLoader: (config: Config) => {
        // Remove all cms.pages from base
        const modulesWithCmsPages = config.modules.filter(
            (module) =>
                module.blocks &&
                (module.blocks["cms.pages"] || module.blocks["cms.news"]) &&
                module.key !== "cr7-fitness"
        );
        modulesWithCmsPages.forEach((module) => {
            module.blocks!["cms.pages"] = [];
            module.blocks!["cms.news"] = [];
        });

        return config;
    },
});
