import { StaticPageConfig } from "@7egend/dxp.core/lib/modules/cms/StaticPages/types";

// Pages
import { HOME_PAGE } from "./homePage";
import { CLUBS_PAGE } from "./clubsPage";
import { PERSONAL_TRAINING_PAGE } from "./personalTrainingPage";
import { CLASSES_PAGE } from "./classesPage";
import { FRANCHISE_PAGE } from "./franchisePage";
import { CLASS_PAGE } from "./classPage";
import { CLUB_PAGE } from "./clubPage";
import { FREE_PASS } from "./freePass";
import { CONTACTS } from "./contacts";
import { WORK_WITH_US } from "./workWithUs";

export const STATIC_PAGES_CONFIG: StaticPageConfig = {
    sections: {
        ...HOME_PAGE.sections,
        ...CLUBS_PAGE.sections,
        ...PERSONAL_TRAINING_PAGE.sections,
        ...CLASSES_PAGE.sections,
        ...FRANCHISE_PAGE.sections,
        ...CLASS_PAGE.sections,
        ...CLUB_PAGE.sections,
        ...FREE_PASS.sections,
        ...CONTACTS.sections,
        ...WORK_WITH_US.sections
    }
};