import { StaticPageConfig } from "@7egend/dxp.core/lib/modules/cms/StaticPages/types";

export const CLASSES_PAGE: StaticPageConfig = {
    sections: {
        "4": {
            image: "../assets/pages/classes.svg",
            containers: {
                "classes/free_pass": {
                    blocks: [
                        {
                            types: ["201_1"],
                            label: "Desktop",
                        },
                        {
                            types: ["201_1"],
                            label: "Mobile",
                        },
                        {
                            types: ["3_1"],
                        },
                        {
                            types: ["8_1"],
                        },
                    ]
                },
            }
        }
    }
};