import { StaticPageConfig } from "@7egend/dxp.core/lib/modules/cms/StaticPages/types";

export const CLUB_PAGE: StaticPageConfig = {
    sections: {
        "6": {
            containers: {
                "club/lets_train": {
                    blocks: [
                        {
                            types: ["201_1"],
                            label: "Desktop",
                        },
                        {
                            types: ["201_1"],
                            label: "Mobile",
                        },
                        {
                            types: ["3_1"],
                        },
                        {
                            types: ["8_1"],
                        },
                    ]
                },
            }
        }
    }
};