import { StaticPageConfig } from "@7egend/dxp.core/lib/modules/cms/StaticPages/types";

export const CONTACTS: StaticPageConfig = {
    sections: {
        "9": {
            containers: {
                "contacts/contact": {
                    blocks: [
                        {
                            types: ["12"],
                        },
                    ]
                },
            }
        }
    }
}