import { StaticPageConfig } from "@7egend/dxp.core/lib/modules/cms/StaticPages/types";

export const FRANCHISE_PAGE: StaticPageConfig = {
    sections: {
        "5": {
            image: "../assets/pages/franchise.svg",
            containers: {
                "franchise/overview": {
                    blocks: [
                        {
                            types: ["3_3"],
                        },
                        {
                            types: ["3_1"],
                        },
                        {
                            types: ["201_1"],
                        },
                    ]
                },
                "franchise/why_invest": {
                    blocks: [
                        {
                            types: ["201_1"],
                        },
                        {
                            types: ["3_2"],
                        },
                        {
                            types: ["3_3"],
                        },
                        {
                            types: ["3_1"],
                        },
                    ]
                },
                "franchise/advantages": {
                    blocks: [
                        {
                            types: ["5"],
                            container: {
                                blocks: [
                                    {
                                        types: ["201_1",],
                                    },
                                    {
                                        types: ["3_3"],
                                    },
                                    {
                                        types: ["3_1"],
                                    },
                                ],
                            },
                        },
                        {
                            types: ["5"],
                            container: {
                                blocks: [
                                    {
                                        types: ["201_1",],
                                    },
                                    {
                                        types: ["3_3"],
                                    },
                                    {
                                        types: ["3_1"],
                                    },
                                ],
                            },
                        },
                        {
                            types: ["5"],
                            container: {
                                blocks: [
                                    {
                                        types: ["201_1",],
                                    },
                                    {
                                        types: ["3_3"],
                                    },
                                    {
                                        types: ["3_1"],
                                    },
                                ],
                            },
                        },
                        {
                            types: ["5"],
                            container: {
                                blocks: [
                                    {
                                        types: ["201_1",],
                                    },
                                    {
                                        types: ["3_3"],
                                    },
                                    {
                                        types: ["3_1"],
                                    },
                                ],
                            },
                        },
                    ]
                },
                "franchise/video": {
                    blocks: [
                        {
                            types: ["201_2"],
                        },
                    ]
                },
                "franchise/our_advantages": {
                    blocks: [
                        {
                            types: ["3_2"],
                        },
                        {
                            types: ["3_3"],
                        },
                        {
                            types: ["3_1"],
                        },
                        {
                            types: ["5"],
                            container: {
                                blocks: [
                                    {
                                        types: ["3_1"],
                                    },
                                    {
                                        types: ["3_1"],
                                    },
                                ],
                            },
                        },
                        {
                            types: ["5"],
                            container: {
                                blocks: [
                                    {
                                        types: ["3_1"],
                                    },
                                    {
                                        types: ["3_1"],
                                    },
                                ],
                            },
                        },
                        {
                            types: ["5"],
                            container: {
                                blocks: [
                                    {
                                        types: ["3_1"],
                                    },
                                    {
                                        types: ["3_1"],
                                    },
                                ],
                            },
                        },
                    ]
                },
                "franchise/values": {
                    blocks: [
                        {
                            types: ["5"],
                            container: {
                                blocks: [
                                    {
                                        types: ["201_1",],
                                    },
                                    {
                                        types: ["3_3"],
                                    },
                                    {
                                        types: ["3_1"],
                                    },
                                ],
                            },
                        },
                        {
                            types: ["5"],
                            container: {
                                blocks: [
                                    {
                                        types: ["201_1",],
                                    },
                                    {
                                        types: ["3_3"],
                                    },
                                    {
                                        types: ["3_1"],
                                    },
                                ],
                            },
                        },
                        {
                            types: ["5"],
                            container: {
                                blocks: [
                                    {
                                        types: ["201_1",],
                                    },
                                    {
                                        types: ["3_3"],
                                    },
                                    {
                                        types: ["3_1"],
                                    },
                                ],
                            },
                        },
                    ]
                },
                "franchise/partners": {
                    blocks: [
                        {
                            types: ["3_2"],
                        },
                        {
                            types: ["3_3"],
                        },
                        {
                            types: ["3_1"],
                        },
                        {
                            types: ["5"],
                            container: {
                                blocks: [
                                    {
                                        types: ["3_1"],
                                    },
                                    {
                                        types: ["3_1"],
                                    },
                                ],
                            },
                        },
                        {
                            types: ["5"],
                            container: {
                                blocks: [
                                    {
                                        types: ["3_1"],
                                    },
                                    {
                                        types: ["3_1"],
                                    },
                                ],
                            },
                        },
                        {
                            types: ["5"],
                            container: {
                                blocks: [
                                    {
                                        types: ["3_1"],
                                    },
                                    {
                                        types: ["3_1"],
                                    },
                                ],
                            },
                        },
                    ]
                },
                "franchise/contact": {
                    blocks: [
                        {
                            types: ["12"],
                        },
                    ]
                },
            }
        }
    }
};