import React from 'react';
import { NewsEditor } from '@7egend/dxp.core/lib/modules/cms/News/NewsEditor';
import { Input } from '@7egend/dxp.core/lib/components/Input';
import { APP_TRANSLATIONS } from "./i18n";
import { withI18n, WithI18nProps } from "@7egend/web.core/lib/components/withI18n";
import { News, NewsCreateInput, NewsUpdateInput } from '@7egend/dxp.core/lib/dlos/cms/news';
import { DloInput } from '@7egend/web.core/lib/extensions/dlo';
import { PageEditorRenderOptions } from '@7egend/dxp.core/lib/components/Structure/PageEditor/PageEditor';

interface OverridedNews extends News {
    localization: string
}

interface OverridedNewsInput extends NewsCreateInput {
    localization: string
}

class NewsEditorOverrideComponent extends React.PureComponent<WithI18nProps> {
    render() {

        const { t } = this.props;

        const flex: any = {
            fields: [
                {
                    component: ({ data, setData }: PageEditorRenderOptions<OverridedNews>) => <Input
                        key={t("news.editor.fieldLocalizationLabel")}
                        label={t("news.editor.fieldLocalizationLabel")}
                        name={t("news.editor.fieldLocalizationLabel")}
                        placeholder={t("news.editor.fieldLocalizationPlaceholder")}
                        type="text"
                        value={data?.localization || ""}
                        onChange={(value: string) =>
                            setData({
                                ...data,
                                localization: value
                            })
                        }
                    />
                },
            ],
            beforeSubmit: [
                (request: DloInput<NewsCreateInput | NewsUpdateInput>, options: any) => {
                    (request.body as OverridedNewsInput).localization = options.data.localization;
                    return request;
                }
            ]
        };

        return (
            <NewsEditor
                flex={flex}
            />
        );
    }
}

export const NewsEditorOverride = withI18n(APP_TRANSLATIONS)(NewsEditorOverrideComponent);